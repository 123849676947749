<template>
  <div v-frag>
    <v-container fluid>
      <v-row align='center' align-content='center' justify='center'>
        <v-col>
          <v-card class='rounded-lg' elevation='3'>
            <v-card-title class='grey--text mb-2'>
              <v-row>
                <v-col class='avatarCharity' lg='4' md='4' sm='12'>
                  <v-avatar color='grey' size='110'>
                    <v-img
                      :src='charity.logo'
                      lazy-src='http://beepeers.com/assets/images/commerces/default-image.jpg'
                    ></v-img>
                  </v-avatar>
                  <p class='charity-name'>{{ charity.name }}</p>
                </v-col>
                <v-col lg='8' md='8' sm='12'>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class='text-left'>Total Donations</th>
                        <th class='text-left'>Total Need</th>
                        <th class='text-left'>Commission Amount</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for='item in desserts' :key='item.name'>
                        <td>{{ formatMoney(charity.donations) }}</td>
                        <td>{{ formatMoney(item.totalNeed) }}</td>
                        <td>
                          {{ formatMoney((10 * charity.donations) / 100) }}
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <v-row align='center' align-content='center' justify='center'>
        <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
          <v-card class='rounded-lg' elevation='3'>
            <v-card-title class='grey--text mb-2'>
              Transaction History
              <v-spacer></v-spacer>
              <v-col cols='12' lg='5' md='5' sm='12' xl='5' xs='12'>
                <v-text-field
                  v-model='search'
                  class='rounded-lg'
                  dense
                  filled
                  hide-details
                  placeholder='Search'
                  prepend-inner-icon='mdi-magnify'
                  rounded
                ></v-text-field>
              </v-col>
            </v-card-title>
            <v-container fluid>
              <v-data-table
                :headers='headers'
                :items='events'
                :loading='loading'
                :search='search'
                class='elevation-1 data-table-border-radius'
              >
                <template v-slot:[`item.date`]='{ item }'>
                  <span> {{ formatToDate(item.date) }} </span>
                </template>
                <template v-slot:[`item.donation_amount`]='{ item }'>
                  <span> {{ formatMoney(item.donation_amount) }} </span>
                </template>
              </v-data-table>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import charityEventsServices from '../../services/charityEvents/index'

export default {
  data() {
    return {
      search: '',
      loading: true,
      charity: {},
      headers: [
        {
          text: 'Date',
          align: 'start',
          sortable: false,
          value: 'date',
          divider: true
        },
        {text: 'Need', value: 'need', divider: true},
        {text: 'Employee', value: 'employee_name', divider: true},
        {text: 'Company', value: 'member_name', divider: true},
        {text: 'Amount', value: 'donation_amount', divider: true}
      ],
      desserts: [
        {
          totalDonations: 0,
          totalNeed: 0,
          commissionAmount: 0
        }
      ],
      events: []
    }
  },
  methods: {
    async initialize() {
      try {
        const res = await charityEventsServices.getAdminEvents(
          this.$route.params.id
        )
        this.events = []
        let totalNeed = 0
        const eventsDonations = res.filter(
          (charEvents) => charEvents.event_type_id != 1
        )
        eventsDonations.forEach((charEvent) => {
          totalNeed += charEvent.mount_required
          charEvent.charity_requests.forEach((charRequest) => {
            this.events.push({
              ...charRequest,
              need: charEvent.description
            })
          })
        })
        this.desserts[0].totalNeed = totalNeed
        this.loading = false
      } catch (err) {
        this.loading = false
      }
    },
    getCharity() {
      this.$store.getters.charities.forEach((element) => {
        if (element.id == this.$route.params.id) {
          this.charity = element
        }
      })
    },
    formatMoney: function(value) {
      if (value) {
        return value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        })
      }
    },
    formatToDate: (value) => {
      if (value) {
        const date = new Date(value)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      }
    }
  },
  created: function() {
    this.initialize()
    this.getCharity()
  }
}
</script>

<style>
.charity-name {
  word-wrap: break-word;
}
.avatarCharity {
  display: flex;
  align-items: center;
}
.avatarCharity p {
  margin-left: 15px;
  line-height: 1.2;
  word-break: normal;
}
@media (min-width: 960px) and (max-width: 1024px) {
  .avatarCharity .v-avatar,
  .avatarCharity .v-avatar * {
    width: 80px !important;
    height: 80px !important;
    min-width: 80px !important;
  }
  .avatarCharity p {
    font-size: 16px;
  }
}
</style>
